import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import GoogleIcon from "../../Images/google.svg";
import { createTheme } from "@mui/material/styles";
import AlternateEmailIcon from "../../Images/emailat.svg";
import SimCardIcon from "../../Images/sim.svg";
import ErrorInfo from "../../Images/errorInfoWhite.svg";
import Logo from "../../Images/logo.svg";
import BackgroundImage from "../../Images/home/loginBg.json";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { loginService } from "../../_services/login";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "../../Images/success.svg";
import Popup from 'reactjs-popup'
import Lottie from 'react-lottie';
import DisableRightClick from "../../_helpers/DisableRightClick";


const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    height: "100vh",
    position: "relative !important",
    width: "100% !important",
  },
  helpertextContent: {
    color: "#fff !important",
    fontSize: "10px !important",
    marginLeft: "6px !important",
  },
  google: {
    fontFamily:"Inter !important",
    fontSize:"14px !important",
    marginLeft: "10px !important",
    color: "#fff",
    fontWeight: "600  !important",
    lineHeight: "14px !important",
    fontStyle: "normal",
  },
  emailBtn: {
    marginTop: "1rem !important",
    background: "#2B2B2B !important",
    border: "2px solid #2B2B2B !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    height: "3.5rem",
    "&:hover": {
      backgroundColor: "#2B2B2B",
    },
  },
  icon: {
    color: "#AC93FF",
  },
  container: {
    // padding: "20% 3%",
    marginBottom: "55px !important"
  },
  cardHeaderMargin: {
    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px !important",
    lineHeight: "30px !important",
    color: "#AC93FF",
  },
}));

const Signup = () => {


  const navigate = useNavigate();
  const classes = useStyles();
  const [googleResponse, setGoogleResponse] = useState();
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [show, setShow] = useState(true);
  const [timer, setTimer] = useState(3);
  const [deviceType, setDeviceType] = useState('');

  // useEffect(() => {

  useEffect(() => {
    if (window.ReactNativeWebView != undefined) {
      let data = {
        getAppVersion: true
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
      console.log("post message sent")
    } else {
      console.log("post message not sent")
    }
    const userAgent = navigator.platform.toLowerCase();
    if (userAgent.includes('iphone')) {
      setDeviceType('iOS');
    } else if (userAgent.includes('android')) {
      setDeviceType('Android');
    } else {
      setDeviceType('Unknown');
    }
 

  }, [])

  function start() {
    gapi.client.init({
      clientId: "807396249480-h6judq2ccehrkg1d29h72jdrnaph3e0o.apps.googleusercontent.com",
      scope: 'email',
      plugin_name: "chat"
    });
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BackgroundImage,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  React.useEffect(() => {
    document.addEventListener("message", function (e) {
      if (e.data.isAppleLogin==false && e.data.userData.email_verified == true) {
        responseSuccessGoogle(e.data)
      } else if(e.data.isAppleLogin==true){
        responseSuccessApple(e.data.userData)
      }else{
        setGoogleResponse("failed");
        setSnackbarTitle("Google Authentication Failed. Please try later")
      }
    });
    return () =>
      document.removeEventListener("message", function (e) {
      });
  }, []);

  React.useEffect(() => {
    document.addEventListener("needAppUpdate", function (e) {
      if (e.data.needAppUpdate == true) {
        if (e.data.deviceType == "ios") {
          window.open("https://apps.apple.com/in/app/unlistedkart/id1573104285", "_parent")
        } else {
          window.open("https://play.google.com/store/apps/details?id=com.unlisted.unlistedkart", "_parent")
        }
      }
    });
    return () =>
      document.removeEventListener("needAppUpdate", function (e) {
      });
  }, []);
  const callGoogleLoginFunction = () => {
    // gapi.load('client:auth2', start);
    if (window.ReactNativeWebView != undefined) {
      let data = {
        googleLogin: true
      }
       window.ReactNativeWebView.postMessage(JSON.stringify(data));
      console.log("post message sent")
    } else {
      console.log("post message not sent")
    }
  }

  const callAppleLoginFunction = () => {
    if (window.ReactNativeWebView != undefined) {
      let data = {
        appleLogin: true
      }

      window.ReactNativeWebView.postMessage(JSON.stringify(data));
      console.log("post message sent")
    }else{
      console.log("post message not sent")
    }
  }



  // }, []);
  const responseErrorGoogle = (response) => {
    console.log("error", response);
  };

  const responseSuccessApple=(response)=>{
    loginService.appleAuthentication(response).then((res) => {
      if (res.status === 200) {
        setGoogleResponse("success");
        setSnackbarTitle("Google Authentication Successful")
        setTimeout(() => {
          navigate("/signupphone?redirectedFromApp=false", {
            replace: true,
            state: { email: res.data.data.email, userId: res.data.data.created_user_id, type: "register" },
          });
        }, 2000);
      } else if (res.data.message === "EMAIL_ADDRESS_ALREADY_EXISTS" && res.data.data.isEmailVerified === true && res.data.data.isMobileVerified === false) {
        setGoogleResponse("success");
        setSnackbarTitle("Google Authentication Successful")

        setTimeout(() => {
          navigate("/signupphone", {
            replace: true,
            state: { email: res.data.data.email, userId: res.data.data.created_user_id, type: "register" }
          });
        }, 2000);
      } else if (res.data.message === "EMAIL_ADDRESS_ALREADY_EXISTS" && res.data.data.isEmailVerified === true && res.data.data.isMobileVerified === true) {
        setGoogleResponse("success");
        setSnackbarTitle("Google Authentication Successful")
        let email = res.data.data.email.replace(/^(.{2})[^@]+/, "$1*******");
        let str = res.data.data.mobileno
        const regex = /\d(?=(?:\D*\d){4})/g
        var mobile = str.replace(regex, "*")
        navigate("/signupphone", {
          replace: true,
          state: { type: "login", message: `${email} is linked to ${mobile.replace("+", '')}` },
        });

      } else {
        alert(res.data.message, res.data.data.isEmailVerified, res.data.data.isMobileVerified)
        setGoogleResponse("failed");
        setSnackbarTitle("Something went wrong")
      }
    });
  }
  const responseSuccessGoogle = (response) => {
    loginService.googleAuthentication(response).then((res) => {
      if (res.status === 200) {
        setGoogleResponse("success");
        setSnackbarTitle("Google Authentication Successful")
        setTimeout(() => {
          navigate("/signupphone?redirectedFromApp=false", {
            replace: true,
            state: { email: res.data.data.email, userId: res.data.data.created_user_id, type: "register" },
          });
        }, 2000);
      } else if (res.data.message === "EMAIL_ADDRESS_ALREADY_EXISTS" && res.data.data.isEmailVerified === true && res.data.data.isMobileVerified === false) {
        setGoogleResponse("success");
        setSnackbarTitle("Google Authentication Successful")

        setTimeout(() => {
          navigate("/signupphone", {
            replace: true,
            state: { email: res.data.data.email, userId: res.data.data.created_user_id, type: "register" }
          });
        }, 2000);
      } else if (res.data.message === "EMAIL_ADDRESS_ALREADY_EXISTS" && res.data.data.isEmailVerified === true && res.data.data.isMobileVerified === true) {
        setGoogleResponse("success");
        setSnackbarTitle("Google Authentication Successful")
        let email = res.data.data.email.replace(/^(.{2})[^@]+/, "$1*******");
        let str = res.data.data.mobileno
        const regex = /\d(?=(?:\D*\d){4})/g
        var mobile = str.replace(regex, "*")
        navigate("/signupphone", {
          replace: true,
          state: { type: "login", message: `${email} is linked to ${mobile.replace("+", '')}` },
        });

      } else {
        alert(res.data.message, res.data.data.isEmailVerified, res.data.data.isMobileVerified)
        setGoogleResponse("failed");
        setSnackbarTitle("Something went wrong")
      }
    });
  };
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        Mui: {
          root: {
            focused: {
              borderColor: "yellow",
            },
          },
        },
        MuiOutlinedInput: {
          root: {
            borderColor: "blue",
          },
        },
      },
    });

    const navigateLogin = () => {
      navigate("/signupphone", {
        replace: true,
        state: { type: "login" },
      });
    };
  

  useEffect(() => {
    setTimeout(() => {
      setGoogleResponse();
    }, 3000);
  }, [googleResponse])

  useEffect(() => {
    const resendOtpSec = setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setShow(false)
        setTimeout(() => {
          setShow(true);
          setTimer(3)
        }, 2000);
      }
    }, 600);
  }, [timer]);

  return (
    <>
      <Page>
      <DisableRightClick>
        <Box className={classes.boxcontainer}>

          <Lottie
            style={{
              position: "absolute", background: "#0d0d0d",
              zIndex: -1
            }}
            options={defaultOptions}
          />
          {googleResponse === "failed" ? (
            <div style={{ background: "#DD4A47", height: "1.5rem" }}>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={ErrorInfo}
                  alt="verified"
                  width="14"
                  height="14"
                />
                <Typography className={classes.helpertextContent}>
                  {snackbarTitle}
                </Typography>
              </div>
            </div>
          ) : googleResponse === "success" ? (
            <div style={{ background: "#61AE3D", height: "1.5rem" }}>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={CheckCircleOutlineIcon}
                  alt="verified"
                  width="14"
                  height="14"
                />
                <Typography className={classes.helpertextContent}>
                  {snackbarTitle}
                </Typography>
              </div>
            </div>
          ) : (
            <div style={{ background: "none", height: "1.5rem" }} />
          )}

          <Container>
            <img
              src={Logo}
              alt="Logo"
              width="184"
              height="33"
              style={{ marginTop: "1.75rem", marginLeft: "12px" }}
            />
          </Container>
          <Container

            style={{
              position: "absolute",
              bottom: "40px",
              left: "50%",
              transform: "translate(-50%, -0%)",
            }}
          >
            <Grid container direction="column">
              <Container className={classes.container} style={{ textAlign: "center", opacity: show ? "1" : "0", transition: "opacity 0.8s" }}>
                <Typography
                  className={classes.cardHeaderMargin}
                  style={{ color: "#fff" }}
                >
                  Unlisted Market
                  <span
                    style={{ color: "#AC93FF", paddingLeft: "5px" }}
                  >
                    Simplified
                  </span>
                </Typography>
              </Container>
              {/* { */}
                {/* deviceType == 'iOS' ?   <Grid>
                <Button
                  className={classes.emailBtn}
                  fullWidth
                  variant="contained"
                  onClick={async () => {
                    callAppleLoginFunction()
                  }}
                >
                  
           <span style={{ fontSize: '1.5rem', lineHeight: '1', verticalAlign: 'middle',color:'#9063e6' }}>&#xf8ff;</span>
                  <Typography className={classes.google}>
                    Signup with AppleID
                  </Typography>
                </Button>
              </Grid> : <Grid> */}

              {/* <Button
                         onClick={() => {
                          callGoogleLoginFunction()
                        }}
                        disabled={false}
                        fullWidth
                        className={classes.emailBtn}
                        variant="contained"
                      >
                        <img src={GoogleIcon} alt="" width={20} />
                        <Typography className={classes.google}>
                          Signup with Google
                        </Typography>
                      </Button> */}
                {/* <Popup
                  modal
                  trigger={ */}
                        
                  {/* // <GoogleLogin */}
                  {/* //   clientId="807396249480-h6judq2ccehrkg1d29h72jdrnaph3e0o.apps.googleusercontent.com"
                  //   render={(renderProps) => ( */}
                
                  {/* //   )}
                  //   buttonText="Login"
                  //   onSuccess={responseSuccessGoogle}
                  //   onFailure={responseErrorGoogle}
                  //   cookiePolicy={"single_host_origin"}
                  // />
                
                // }
                //   closeOnDocumentClick={false}
                //   className={classes.signatureCanvas}> */}
                  {/* {close => (
                    <>

                      <button>Save</button>

                    </>
                  )} */}
                {/* // </Popup> */}

              {/* </Grid>} */}
             
            
                
                
                 {/*  <Grid>
                  <Button
                    className={classes.emailBtn}
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      navigate("/signupemail");
                    }}
                  >
                    <img src={AlternateEmailIcon} alt="Logo" width="20" />
                    <Typography className={classes.google}>
                      Signup with Email
                    </Typography>
                  </Button>
                </Grid> */}
 



             {/*   <Grid container style={{ alignItems: "end", alignItem: "center", margin: "16px 0" }}>
                <Grid item xs={5.5} pt={1}>
                  <hr
                    style={{
                      backgroundColor: "#494949",
                      border: "none",
                      height: "1px",
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      textAlign: "center",
                      color: "#494949",
                      fontFamily: "Rubik",
                    }}
                  >
                    OR
                  </Typography>
                </Grid>
                <Grid item xs={5.5}>
                  <hr
                    style={{
                      backgroundColor: "#494949",
                      border: "none",
                      height: "1px",
                    }}
                  />
                </Grid>
              </Grid>*/}
              <Grid>
                <Button
                  onClick={navigateLogin}
                  className={classes.emailBtn}
                  fullWidth
                  variant="contained"
                >
                  <img src={SimCardIcon} alt="Logo" width="15" />
                  <Typography className={classes.google}>
                    Login with Phone Number
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
        </DisableRightClick>
      </Page>
    </>
  );
};

export default Signup;